import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Divider,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import './App.css';

const ResetPassword = (props) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);
  let history = useHistory();

  const handleSubmit = async (event) => {
    setSubmitted(true);
    const endpoint = process.env.REACT_APP_REALM_ENDPOINT;
    await fetch(endpoint + '/send-password-reset-sms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: '+1' + phoneNumber.match(/\d+/g).join(''),
      }),
    });
  };

  return (
    <div
      style={{
        backgroundImage: 'url("images/login-bg.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Grid
        textAlign="center"
        style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            padding: '3em',
          }}
          className="container demo animated"
        >
          <Header
            as="h1"
            icon
            textAlign="center"
            style={{ color: '#fff' }}
          >
            <Header.Content>
              Reset Your Password
              <Header.Subheader style={{ color: '#fff' }}>
                Please enter your phone number. We will send you a
                text message with a password reset link.
              </Header.Subheader>
            </Header.Content>
          </Header>

          <Form size="large">
            <Message
              error
              header="Invalid Password"
              content="Please check your password."
            />
            <Form.Input
              fluid
              icon="phone"
              type="tel"
              iconPosition="left"
              placeholder="123-456-7890"
              value={phoneNumber}
              onChange={(event) => {
                let value = event.target.value;
                value = value.match(/\d+/g);
                if (value) {
                  value = value
                    .join('')
                    .replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3');
                } else {
                  value = '';
                }

                if (value.length < 13) {
                  setPhoneNumber(value);
                }
              }}
            />
            <Button
              //style={{backgroundColor: "#335eea", color: "#fff"}}
              //color="green"
              primary
              fluid
              disabled={phoneNumber.length < 12 || submitted}
              size="large"
              onClick={() => handleSubmit()}
            >
              {submitted ? 'SMS Sent' : 'Send a Reset Password SMS'}
            </Button>
            <Divider />
            <Button
              //style={{backgroundColor: "#335eea", color: "#fff"}}
              //color="green"
              secondary
              fluid
              disabled={!submitted}
              size="large"
              onClick={() => history.replace('/login')}
            >
              Go to Login
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ResetPassword;
