// React
import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import Portal from './Portal';
import Login from './Login';
import Signup from './Signup';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';
import ReactPWAInstallProvider from 'react-pwa-install';

import { StitchAuthProvider, useStitchAuth } from './StitchAuth';

App.propTypes = {};
export default function App() {
  return (
    <ReactPWAInstallProvider enableLogging>
      <StitchAuthProvider>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/resetpassword">
              <ResetPassword />
            </Route>
            <Route path="/newpassword">
              <NewPassword />
            </Route>
            <PrivateRoute path="/portal">
              <Portal />
            </PrivateRoute>
            <Route path="*">
              <Redirect to={{ pathname: '/login' }} />
            </Route>
          </Switch>
        </Router>
      </StitchAuthProvider>
    </ReactPWAInstallProvider>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { isLoggedIn } = useStitchAuth();
  if (isLoggedIn) {
    return <Route {...rest} render={() => children} />;
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
}
