import * as Realm from 'realm-web';

// TODO: Add your Stitch app's App ID
const APP_ID = 'rpm-member-ojjgv';

// TODO: Initialize the app client
//const app = Stitch.hasAppClient(APP_ID)
//  ? Stitch.getAppClient(APP_ID)
//  : Stitch.initializeAppClient(APP_ID);
const app = new Realm.App({ id: APP_ID });

export { app };
