import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Dimmer,
  Loader,
  Divider,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import './App.css';
import { useReactPWAInstall } from 'react-pwa-install';
import { useStitchAuth } from './StitchAuth';

const Login = (props) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [inProcess, setInProcess] = useState(false);
  const [formError, setFormError] = useState(false);
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const { actions } = useStitchAuth();

  let history = useHistory();

  const handleSubmit = async (event) => {
    setInProcess(true);
    const phoneInDbFormat = '+1' + phoneNumber.match(/\d+/g).join('');

    actions
      .handleEmailPasswordLogin(phoneInDbFormat, password)
      .then(() => {
        setInProcess(false);
        history.replace('/portal');
      })
      .catch(() => {
        setInProcess(false);
        setFormError(true);
      });
  };

  const handleInstall = () => {
    pwaInstall({
      title: 'Install Patient Portal',
      logo: 'android-chrome-192x192.png',
      features: (
        <ul>
          <li>Track your readings</li>
          <li>Take mobile surveys</li>
          <li>Communicate with your clinical team</li>
          <li>View education materials</li>
        </ul>
      ),
      description: 'This app helps you keep track of your health!',
    })
      .then(() =>
        alert(
          'App installed successfully or instructions for install shown',
        ),
      )
      .catch(() => alert('User opted out from installing'));
  };

  return (
    <div
      style={{
        backgroundImage: 'url("images/login-bg.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Dimmer inverted active={inProcess}>
        <Loader />
      </Dimmer>
      <Grid
        textAlign="center"
        style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            padding: '3em',
          }}
          className="container demo animated"
        >
          <Header
            as="h1"
            icon
            textAlign="center"
            style={{ color: '#fff' }}
          >
            <Header.Content>
              Welcome to Orma
              <Header.Subheader style={{ color: '#fff' }}>
                Please enter your phone number
              </Header.Subheader>
            </Header.Content>
          </Header>

          <Form size="large" error={formError}>
            <Message
              error
              header="Invalid Password"
              content="Please check your password."
            />
            <Form.Input
              fluid
              icon="phone"
              type="tel"
              iconPosition="left"
              placeholder="123-456-7890"
              value={phoneNumber}
              onChange={(event) => {
                let value = event.target.value;
                value = value.match(/\d+/g);
                if (value) {
                  value = value
                    .join('')
                    .replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3');
                } else {
                  value = '';
                }

                if (value.length < 13) {
                  setPhoneNumber(value);
                }
              }}
            />
            <Form.Input
              fluid
              icon="lock"
              type="password"
              iconPosition="left"
              placeholder="Your Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              //style={{backgroundColor: "#335eea", color: "#fff"}}
              //color="green"
              primary
              fluid
              disabled={phoneNumber.length < 12}
              size="large"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
            <Divider />
            {supported() && !isInstalled() && (
              <Button
                color="teal"
                basic
                fluid
                size="large"
                onClick={handleInstall}
              >
                Install App
              </Button>
            )}
            <p className="white text">
              Forgot your password?{' '}
              <a href="/resetpassword">Reset your password.</a>
              <br />
              If you don't have an account yet, you can{' '}
              <a href="/signup">sign up</a> right away.
            </p>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Login;
