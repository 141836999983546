import * as Realm from 'realm-web';
import { app } from './app.js';

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  return app.currentUser;
}

export function logoutCurrentUser() {
  // Logout the currently logged in user
  return app.currentUser.logOut();
}

export async function signupUser(email, password) {
  return await app.emailPasswordAuth.registerUser(email, password);
}

export async function confirmEmail(token, tokenId) {
  return await app.emailPasswordAuth.confirmUser(token, tokenId);
}

export async function loginUser(email, password) {
  const credentials = Realm.Credentials.emailPassword(
    email,
    password,
  );
  //const credential = new UserPasswordCredential(email, password);
  try {
    const user = await app.logIn(credentials);
    //assert(user.id===app.currentUser.id);
    return user;
  } catch (err) {
    console.error('Failed to log in', err);
  }
}

export async function resetPassword(token, email, newPassword) {
  return await app.emailPasswordAuth.callResetPasswordFunction(
    email,
    newPassword,
    [token],
  );
}
