import React, { useEffect, Suspense } from 'react';
import { Grid, Menu, Icon } from 'semantic-ui-react';
import 'moment-timezone';
import {
  useLocation,
  useRouteMatch,
  Link,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useStitchAuth } from './StitchAuth';

function MemberPortal() {
  const {
    memberOne,
    actions: { loadMember },
  } = useStitchAuth();
  const maxWidth = '700px';
  let { url } = useRouteMatch();
  const location = useLocation();

  const viewList = [
    {
      pathname: 'data',
      title: <Icon name="home" size="big" />,
      isPrivate: true,
      component: React.lazy(() => import('./Data/Data')),
    },
    {
      pathname: 'risk',
      title: <Icon name="heartbeat" size="big" />,
      isPrivate: true,
      component: React.lazy(() => import('./RiskDashboard')),
    },
    {
      pathname: 'awv',
      title: <Icon name="question" size="big" />,
      isPrivate: false,
      component: React.lazy(() => import('./AWV/AWV')),
    },
    {
      pathname: 'devices',
      title: <Icon name="phone" size="big" />,
      isPrivate: true,
      component: React.lazy(() => import('./Devices')),
    },
  ];

  useEffect(() => {
    if (!memberOne) {
      loadMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberOne]);

  return (
    <Grid
      centered
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      <Grid.Row
        centered
        columns={1}
        style={{
          margin: 0,
          padding: 0,
          backgroundImage: 'url("/images/login-bg.jpg")',
          backgroundPosition: 'center',
        }}
      >
        <Grid.Column
          style={{ margin: 0, padding: 0, maxWidth: maxWidth }}
        >
          <Menu
            secondary
            style={{
              backgroundColor: 'rgba(0,0,0,0.3)',
              fontFamily: 'Montserrat, sans-serif',
              minHeight: '4em',
            }}
          >
            {viewList.map((view) => (
              <Menu.Item
                key={view.pathname}
                active={
                  location.pathname === `/portal/${view.pathname}`
                }
              >
                <Link
                  to={`${url}/${view.pathname}`}
                  style={{
                    color: '#fff',
                  }}
                >
                  {view.title}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ margin: 0, padding: 0 }}>
        <Grid.Column
          style={{
            maxWidth: maxWidth,
            minHeight: '100vh',
            padding: '1em',
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path={`${url}`}>
                <Redirect to="/portal/data" />
              </Route>
              {viewList.map((view) => (
                <Route
                  key={view.pathname}
                  path={`${url}/${view.pathname}`}
                  isPrivate={view.isPrivate}
                >
                  <view.component />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        centered
        style={{
          padding: '1em',
          backgroundImage: 'url("/images/login-bg.jpg")',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
        }}
      >
        <Grid.Column textAlign="left" style={{ maxWidth: maxWidth }}>
          <small className="grey text">
            DO NOT SHARE THIS LINK WITH OTHERS.
          </small>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default function MemberAccess(props) {
  return <MemberPortal />;
}
