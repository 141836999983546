import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import './App.css';
import jwt from 'jsonwebtoken';
import { useStitchAuth } from './StitchAuth';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NewPassword = (props) => {
  let query = useQuery();
  let history = useHistory();
  const [password, setPassword] = useState('');
  const [inProcess, setInProcess] = useState(false);
  const [formError, setFormError] = useState(false);
  const token = query.get('token') || '';
  const phoneNumber = jwt.decode(token).phone || '';
  const { actions } = useStitchAuth();

  const handleSubmit = async (event) => {
    setInProcess(true);
    actions
      .handleResetPassword(token, phoneNumber, password)
      .then((d, err) => {
        setInProcess(false);
        history.replace('/login');
      })
      .catch(() => {
        setInProcess(false);
        setFormError(true);
      });
  };

  return (
    <div
      style={{
        backgroundImage: 'url("images/login-bg.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Dimmer inverted active={inProcess}>
        <Loader />
      </Dimmer>
      <Grid
        textAlign="center"
        style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            padding: '3em',
          }}
          className="container demo animated"
        >
          <Header
            as="h1"
            icon
            textAlign="center"
            style={{ color: '#fff' }}
          >
            <Header.Content>
              New Password
              <Header.Subheader style={{ color: '#fff' }}>
                Please enter your new password.
              </Header.Subheader>
            </Header.Content>
          </Header>

          <Form size="large" error={formError}>
            <Message
              error
              header="Couldn't renew your password."
              content={
                <>
                  Your token is expired. Please try sending a new{' '}
                  <Link to="/resetpassword">password reset link</Link>
                  , and try again.
                </>
              }
            />
            <Form.Input
              fluid
              icon="lock"
              type="password"
              iconPosition="left"
              placeholder="Your Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              //style={{backgroundColor: "#335eea", color: "#fff"}}
              //color="green"
              primary
              fluid
              disabled={phoneNumber.length < 12}
              size="large"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default NewPassword;
