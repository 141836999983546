import React from 'react';
import PropTypes from 'prop-types';
import {
  logoutCurrentUser,
  getCurrentUser,
  signupUser,
  loginUser,
  resetPassword,
} from './../realm/authentication';
import ObjectID from 'bson-objectid';
import questions from './AWV/questions.json';

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchAuthContext = React.createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchAuth() {
  const context = React.useContext(StitchAuthContext);
  if (!context) {
    throw new Error(
      `useStitchAuth must be used within a StitchAuthProvider`,
    );
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function StitchAuthProvider(props) {
  const [authState, setAuthState] = React.useState({
    isLoggedIn: getCurrentUser() ? true : false,
    currentUser: getCurrentUser(),
    memberOne: null,
    awvN: -1,
    numAnswered: 0,
    db: {
      members: null,
    },
  });

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(() => {
    // Authentication Actions
    const handleLogout = async () => {
      const { isLoggedIn } = authState;
      if (isLoggedIn) {
        await logoutCurrentUser();
        setAuthState(null);
      } else {
        console.log(`can't handleLogout when no user is logged in`);
      }
    };

    const handleSignup = async (email, password) => {
      return await signupUser(email, password);
    };

    const handleEmailPasswordLogin = async (email, password) => {
      const loggedInUser = await loginUser(email, password);
      const dbMembers = loggedInUser
        .mongoClient('mongodb-atlas')
        .db('test')
        .collection('members');
      const memberOne = await dbMembers.findOne({
        phone: loggedInUser.profile.email,
      });
      setAuthState({
        ...authState,
        isLoggedIn: true,
        currentUser: loggedInUser,
        awvN: initAwvN(memberOne),
        memberOne: memberOne,
        db: {
          members: dbMembers,
        },
      });
      return loggedInUser;
    };

    const handleResetPassword = async (token, email, newPassword) => {
      return await resetPassword(token, email, newPassword);
    };

    const loadMember = async () => {
      const dbMembers = authState.currentUser
        .mongoClient('mongodb-atlas')
        .db('test')
        .collection('members');
      const memberOne = await dbMembers.findOne({
        phone: authState.currentUser.profile.email,
      });
      setAuthState({
        ...authState,
        memberOne: memberOne,
        awvN: initAwvN(memberOne),
        db: {
          members: dbMembers,
        },
      });
    };

    const updateMemberParams = async (params) => {
      const member = JSON.parse(
        await authState.currentUser.functions.updateMemberParams(
          ObjectID(memberOne._id),
          params,
        ),
      );
      setAuthState({ ...authState, memberOne: member });
    };

    const initAwvN = (member) => {
      // get the latest AWV answer
      let awvN = -1;
      if (member) {
        const prevAnswered = Object.entries(
          (member || {}).awv || {},
        ).sort((a, b) => b[1].time - a[1].time);
        if (prevAnswered.length > 0) {
          const qid = prevAnswered[0][0]; // oldest answer; ask the next question after this
          const qIndex = questions.findIndex((el) => el.qid === qid);
          if (qid && qIndex < questions.length - 1) {
            //setN(qIndex + 1);
            awvN = qIndex + 1;
          }
        }
      }
      return awvN;
    };

    const changeAwvN = (step) => {
      setAuthState({ ...authState, awvN: authState.awvN + step });
    };

    const changeNumAnswered = () => {
      setAuthState({
        ...authState,
        numAnswered: authState.numAnswered + 1,
        awvN: authState.awvN + 1,
      });
    };

    const {
      isLoggedIn,
      currentUser,
      awvN,
      numAnswered,
      memberOne,
      db,
    } = authState;
    const value = {
      isLoggedIn,
      currentUser,
      awvN,
      numAnswered,
      memberOne,
      db,
      actions: {
        handleLogout,
        handleSignup,
        handleEmailPasswordLogin,
        handleResetPassword,
        loadMember,
        updateMemberParams,
        initAwvN,
        changeAwvN,
        changeNumAnswered,
      },
    };
    return value;
  }, [authState]);
  return (
    <StitchAuthContext.Provider value={authInfo}>
      {props.children}
    </StitchAuthContext.Provider>
  );
}
StitchAuthProvider.propTypes = {
  children: PropTypes.element,
};
